.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.push-message {
  fill: rgba(0, 0, 0, 0.07);
  width: 100%;
  animation: upNdown 5s ease-in-out infinite;
}

@keyframes upNdown {
  50% {
    transform: translateY(15%);
  }

  100% {
    transform: transateY(-150%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.st0mobile {
  fill: #FFFFFF;
}

.st1mobile {
  fill: #DADADA;
}

.st2mobile {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #C6C6C6;
}

.st3mobile {
  fill: #C6C6C6;
}

.st4mobile {
  font-family: 'SFUIText-Semibold', sans-serif;
}

.st5mobile {
  font-size: 18.5241966px;
}

.svg-container {
  width: 410px;
  position: relative;
  margin: 30px auto;
}

.sms-container {
  min-height: 100px;
  left: 64px;
  position: absolute;
  top: 200px;
  width: 256px;
  max-width: 256px;
  border: 1px solid #ECECEC;
  white-space: pre-wrap;
  border-radius: 10px;
  padding: 0 8px;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  word-wrap: break-word;
  text-align: left;
}

.html-container {
  height: 580px;
  left: 54px;
  position: absolute;
  top: 65px;
  width: 309px;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  border: 0 !important;
  border-radius: 0 0 42px 42px;
  text-align: left;
  padding: 10px;
}

.progressBarAnimation {
  transition: all 1s cubic-bezier(0.45, 0.15, 0.36, 0.71);
}

.dotChannel {
  display: inline-block;
  margin-right: 4px;
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.tootipBox {
  padding: 13px;
  box-shadow: 0px 0px 3px rgb(0 0 0 / 7%), 0px 0px 5px rgb(0 0 0 / 7%), 0px 0px 5px rgb(0 0 0 / 2%);
}