
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* MAIN */
  --color-bg-main: #f7f7f7;
  --color-bg-toolbar: #f7f7f7;
  --color-bg-sidebar: #ffffff;
  --color-bg-avatar-user: #09acf8;
  --color-bg-badge: #ff5d31;
  --color-bg-input: #f7f7f7;
  --color-bg-odd-row: #F9F9F9;
  --color-bg-input-search: #F9F9F9;
  --color-bg-sort-col: #F0F8FF;
  --color-bg-box-col: #ffffff;

  /* TEXT */
  --color-text-default: #8ca0b3;
  --color-text-focus: #09acf8;
  --color-text-input: #91919c;
  --color-text-name: #556677;
  --color-text-header-card: #596A7A;
  --color-text-cel-tab: #91919C;

  /* BORDER */
  --color-border-input: #8CA0B3;
  --color-border-input-focus: #09acf8;
  --color-border-loader: #09acf8;

  /* TABLE */
  --color-active-user: #6DD400;
  --color-deactivated-user: #F7B500;

  /* ICON FILL */
  --color-edit: #0072F1;
  --color-sent: #09ACF8;
  --color-duplicate: #6DD400;
  --color-delete: #F85409;
  --color-add: #F85409;
}

.dark {
  /* MAIN */
  --color-bg-main: #000000;
  --color-bg-toolbar: #242426;
  --color-bg-sidebar: #242426;
  --color-bg-avatar-user: #09acf8;
  --color-bg-badge: #ff5d31;
  --color-bg-input: #596A7A;
  --color-bg-odd-row: #596A7A;
  --color-bg-input-search: #242426;
  --color-bg-sort-col: #F0F8FF;
  --color-bg-box-col: #242426;

  /* TEXT */
  --color-text-default: #8ca0b3;
  --color-text-focus: #09acf8;
  --color-text-input: #91919c;
  --color-text-name: #556677;
  --color-text-header-card: #596A7A;
  --color-text-cel-tab: #91919C;

  /* BORDER */
  --color-border-input: #8CA0B3;
  --color-border-input-focus: #09acf8;
  --color-border-loader: #09acf8;

  /* TABLE */
  --color-active-user: #F7B500;
  --color-deactivated-user: #6DD400;

  /* ICON FILL */
  --color-edit: #0072F1;
  --color-sent: #09ACF8;
  --color-duplicate: #6DD400;
  --color-delete: #F85409;
  --color-add: #F85409;
}

.Toastify__toast-theme--colored.Toastify__toast--default, .Toastify__toast-theme--light {
  background: var(--color-bg-main) !important;
}

.Toastify__toast-container {
  width: 380px !important;
  z-index: 70 !important;
}

input:checked + svg {
  	display: block;
}